<template>
        <v-card class="pa-3" max-width="800">
            <v-overlay :value="isLoading"></v-overlay>
            <h1>{{ $t("account.title") }}</h1>
            <p>{{ $t("account.description") }}</p>
            <v-tabs>
                <v-tab @click="resetError">
                    <v-tooltip bottom v-if="!isLargeScreen">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon left v-bind="attrs" v-on="on">mdi-account-circle</v-icon>
                        </template>
                        <span>{{ $t("account.changePersonalInfo") }}</span>
                    </v-tooltip>
                    <v-icon left v-if="isLargeScreen">mdi-account-circle</v-icon>
                    <span v-if="isLargeScreen">{{ $t("account.changePersonalInfo") }}</span>
                </v-tab>
                <v-tab @click="resetError">
                    <v-tooltip bottom v-if="!isLargeScreen">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon left v-bind="attrs" v-on="on">mdi-lock-reset</v-icon>
                        </template>
                        <span>{{ $t("account.changePassword") }}</span>
                    </v-tooltip>
                    <v-icon left v-if="isLargeScreen">mdi-lock-reset</v-icon>
                    <span v-if="isLargeScreen">{{ $t("account.changePassword") }}</span>
                </v-tab>

                <v-tab-item>
                    <!-- Personal Info Tab -->
                    <v-spacer class="my-3"></v-spacer>
                    <p>{{ $t("account.descriptionPersonal") }}</p>
                    <validation-observer ref="observer">
                        <v-form @submit.prevent="submitPersonalInfo">
                            <v-text-field
                                v-model="form.name"
                                required
                                :rules="rules.name"
                                autocomplete="off"
                                :label="$t('account.label_name')"
                                v-on:keyup="resetError"
                            ></v-text-field>

                            <v-text-field
                                v-model="form.email"
                                required
                                type="email"
                                :rules="rules.email"
                                autocomplete="off"
                                :label="$t('account.label_email')"
                                v-on:keyup="resetError"
                            ></v-text-field>

                            <v-text-field
                                v-model="form.currentPasswordForEmail"
                                required
                                :type="showcurrentPasswordForEmail ? 'text' : 'password'"
                                :rules="rules.currentPasswordForEmail"
                                autocomplete="off"
                                :append-icon="showcurrentPasswordForEmail ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="() => (showcurrentPasswordForEmail = !showcurrentPasswordForEmail)"
                                :label="$t('account.label_currentPassword')"
                                v-on:keyup="resetError"
                            ></v-text-field>
                            <transition name="fade">
                                <v-alert
                                    type="success"
                                    v-if="successFeedback.show"
                                >
                                    {{ successFeedback.message }}
                                </v-alert>
                            </transition>
                            <transition name="fade">
                                <v-alert
                                    type="error"
                                    v-if="errorFeedback.show"
                                >
                                    {{ errorFeedback.message }}
                                </v-alert>
                            </transition>

                            <v-row class="mt-3">
                                <v-spacer></v-spacer>
                                <v-btn 
                                    color="primary" 
                                    type="submit" 
                                    name="submitPersonalInfo" 
                                    value="submitPersonalInfo" 
                                    class="mr-5 mb-5"
                                >
                                    {{ $t("account.label_submit") }}
                                </v-btn>
                            </v-row>
                        </v-form>
                    </validation-observer>
                </v-tab-item>

                <v-tab-item>
                    <!-- Change Password Tab -->
                    <v-spacer class="my-3"></v-spacer>
                    <p>{{ $t("account.descriptionPassword") }}</p>
                    <validation-observer ref="observer">
                        <v-form @submit.prevent="submitPasswordChange">
                            <v-text-field
                                v-model="form.currentPassword"
                                required
                                :type="showcurrentPassword ? 'text' : 'password'"
                                :rules="rules.currentPassword"
                                autocomplete="off"
                                :append-icon="showcurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="() => (showcurrentPassword = !showcurrentPassword)"
                                :label="$t('account.label_currentPassword')"
                                v-on:keyup="resetError"
                            ></v-text-field>

                            <v-text-field
                                v-model="form.newPassword"
                                required
                                :type="showNewPassword ? 'text' : 'password'"
                                :rules="rules.newPassword"
                                autocomplete="off"
                                :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="() => (showNewPassword = !showNewPassword)"
                                :label="$t('account.label_newPassword')"
                                v-on:keyup="resetError"
                            ></v-text-field>

                            <v-text-field
                                v-model="form.newPasswordConfirm"
                                required
                                :type="showNewPasswordConfirm ? 'text' : 'password'"
                                :rules="rules.newPasswordConfirm"
                                autocomplete="off"
                                :append-icon="showNewPasswordConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="() => (showNewPasswordConfirm= !showNewPasswordConfirm)"
                                :label="$t('account.label_newPasswordConfirm')"
                                v-on:keyup="resetError"
                            ></v-text-field>

                            <transition name="fade">
                                <v-alert
                                    type="success"
                                    v-if="successFeedback.show"
                                >
                                    {{ successFeedback.message }}
                                </v-alert>
                            </transition>
                            <transition name="fade">
                                <v-alert
                                    type="error"
                                    v-if="errorFeedback.show"
                                >
                                    {{ errorFeedback.message }}
                                </v-alert>
                            </transition>
                            <v-row class="mt-3">
                                <v-spacer></v-spacer>
                                <v-btn 
                                    color="primary" 
                                    type="submit" 
                                    name="submitPasswordChange" 
                                    value="submitPasswordChange" 
                                    class="mr-5 mb-5"
                                >
                                    {{ $t("account.label_submit") }}
                                </v-btn>
                            </v-row>
                        </v-form>
                    </validation-observer>
                </v-tab-item>
            </v-tabs>
        </v-card>
</template>


<script>
import Vue from 'vue';
import { mapGetters, mapActions} from 'vuex';
import { ValidationObserver, extend, setInteractionMode, configure } from 'vee-validate';
import { required, email, min} from 'vee-validate/dist/rules';
import { ILLI_AUTH_SERVER_URL } from '@/config';
import axios from 'axios';

export default Vue.extend({
    name: "sign-in",
    components: {
        ValidationObserver
    },
    beforeMount () {
        setInteractionMode('eager')
        extend('min', min)
        extend('required', required)
        extend('email', email)
        configure({
            defaultMessage: (field, values) => {
                values._field_ = this.$t(`login.label_${field}`);
                return this.$t(`validations.messages.${values._rule_}`, values);
            }
        });
    },

    computed: {
        ...mapGetters({
            userGetter: "auth/user",
        }),
        user() {
            return this.userGetter || { email: '', /* other properties... */ };
        }
    },
    data () {
        return ({
            isLoading: false,
            isLargeScreen: true,
            changeEmail: false,
            changePassword: false,
            showcurrentPasswordForEmail: false,
            showcurrentPassword: false,
            showNewPassword: false,
            showNewPasswordConfirm: false,
            form: {
                name: '',
                email: '',
                currentPasswordForEmail: '',
                currentPassword: '',
                newPassword: '',
                newPasswordConfirm: '',
            },
            errorFeedback: {
                show: false,
                message: null
            },
            successFeedback: {
                show: false,
                message: null,
                timeoutId: null
            },
            rules: {
                email: [
                    v => (this.changeEmail ? !!v : true) || 'Email is verplicht.', 
                    v => (this.changeEmail ? /.+@.+\..+/.test(v) : true) || 'Mailadres moet geldig zijn.',
                    v => (this.changeEmail ? v !== this.user.email : true) || 'Het nieuwe mailadres moet verschillen van de oude.'
                ],
                name: [
                    v => !!v || 'Name is required', v => (v && v.length >= 1) || 'Name must be at least 1 characters'
                ],
                currentPassword: [
                    v => !!v || 'Wachtwoord is verplicht.'
                ],
                newPassword: [
                    v => (this.changePassword ? !!v : true) || 'Wachtwoord is verplicht.',
                    v => (this.changePassword && v ? v.length >= 8 : true) || 'Wachtwoord moet minimaal 8 tekens lang zijn.'
                ],
                newPasswordConfirm: [
                    v => (this.changePassword ? v == this.form.newPassword : true) || 'Wachtwoord moet overeenkomen.'
                ],
            },
            errorMessages: {
                nameRequired: 'Naam is verplicht (en dient minimaal 1 karakter lang te zijn).',
                emailRequired: 'Geldig nieuw mailadres is verplicht.',
                newPasswordRequired: 'Geldig nieuw wachtwoord is verplicht (en dient minimaal 8 karakters lang te zijn).',
                newPasswordMismatch: 'Nieuw wachtwoord moet overeenkomen.',
                currentPasswordRequired: 'Huidig wachtwoord is verplicht.'
            }
        })
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn'
        }),
        validate(condition, message) {
            if (condition) {
                this.showErrorFeedback(message);
                return true;
            }
        return false;
    },
    resetError() {
      this.errorFeedback.show = false;
    },
    showErrorFeedback(message) {
        this.errorFeedback.message = message;
        this.errorFeedback.show = true;
    },
    showSuccessFeedback(message) {
        this.successFeedback.message = message;
        this.successFeedback.show = true;
        if (this.successFeedback.timeoutId) {
            clearTimeout(this.successFeedback.timeoutId);
        }
        this.successFeedback.timeoutId = setTimeout(() => { 
            this.successFeedback.show = false; 
        }, 10000);
    },
    submitPersonalInfo() {
            this.$refs.observer.validate()
            if (this.validate(!this.form.name || this.form.name.trim().length < 1, this.errorMessages.nameRequired)) return;
            if (this.validate(!this.form.email || !/.+@.+\..+/.test(this.form.email), this.errorMessages.emailRequired)) return;
            if (this.validate(!this.form.currentPasswordForEmail, this.errorMessages.currentPasswordRequired)) return;
            
            let form = {
                name: this.form.name.trim(),
                email: this.form.email.trim(),
                currentPassword: this.form.currentPasswordForEmail.trim()
            };

            this.isLoading = true;
            axios.patch(`${ILLI_AUTH_SERVER_URL}/user/info/personal`, form)
                .then(() => {
                    this.isLoading = false;
                    let succesBericht = 'Uw persoonlijke informatie is gewijzigd.';
                    if (form.email !== this.user.email) {
                        succesBericht = 'Er is een mail naar uw nieuwe mailadres gestuurd, vergeet niet om deze te bevestigen. Vervolgens is uw mailadres succesvol gewijzigd.';
                    }
                    this.showSuccessFeedback(succesBericht);
                    this.currentPasswordForEmail = '';
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response && error.response.data) {
                        let errorMessage;
                        if (error.response.status === 400 || error.response.status === 401 || error.response.status === 409) {
                            errorMessage = error.response.data.error;
                        } else {
                            errorMessage = error.response.data;
                        }
                        this.showErrorFeedback(errorMessage);
                    } else {
                        console.error(error);
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
    },
    submitPasswordChange() {
        this.$refs.observer.validate()
        if (this.validate(!this.form.newPassword || this.form.newPassword.length < 8, this.errorMessages.newPasswordRequired)) return;
        if (this.validate(this.form.newPassword !== this.form.newPasswordConfirm, this.errorMessages.newPasswordMismatch)) return;
        if (this.validate(!this.form.currentPassword, this.errorMessages.currentPasswordRequired)) return;

        let form = {
            currentPassword: this.form.currentPassword.trim(),
            newPassword: this.form.newPassword.trim(),
            newPasswordConfirm: this.form.newPasswordConfirm.trim()
        };


        this.isLoading = true;
        axios.patch(`${ILLI_AUTH_SERVER_URL}/user/info/password`, form)
            .then(() => {
                this.isLoading = false;
                this.showSuccessFeedback('Uw wachtwoord is succesvol gewijzigd');
                this.currentPassword = '';
                this.newPassword = '';
                this.newPasswordConfirm = '';
            })
            .catch(error => {
                this.isLoading = false;
                if (error.response && error.response.data) {
                    let errorMessage;
                    if (error.response.status === 400 || error.response.status === 401 || error.response.status === 409) {
                        errorMessage = error.response.data.error;
                    } else {
                        errorMessage = error.response.data;
                    }
                    this.showErrorFeedback(errorMessage);
                } else {
                    console.error(error);
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        onResize() {
            this.isLargeScreen = window.innerWidth > 600;
        },
    },
    mounted() {
        this.form.name = this.user.name;
        this.form.email = this.user.email;
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
})
</script>

<style lang="scss">

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

</style>